
import Vue from "vue";
import Component from "vue-class-component";

import Divider from "@/components/ewa/common/Divider.vue";
import { IconDefinition, faCheck } from "@fortawesome/free-solid-svg-icons";
import { StoreActionTypes } from "@/store/types";

@Component({
  components: {
    Divider,
  },
})
export default class EwaIntroductionModal extends Vue {
  private checkIcon: IconDefinition = faCheck;

  openBitrix() {
    window.open("https://vendors.bitrix24.ru/", "_blank");
  }

  onClose() {
    (this.$root as any).closeDialog();
    this.$store.dispatch(
      StoreActionTypes.SET_AUTHENTIFICATION_SHOW_TUTORIAL_VIDEO,
      true
    );
    window.localStorage.setItem("ewaTutorialChecked", "true");
  }
}
